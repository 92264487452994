@font-face {
    font-family: "GmarketSansTTF";
    src: url("https://font.missgorealty.com/fonts/GmarketSansTTFBold.ttf") format("truetype");
    font-weight: 700;
}
@font-face {
    font-family: "GmarketSansTTF";
    src: url("https://font.missgorealty.com/fonts/GmarketSansTTFMedium.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "GmarketSansTTF";
    src: url("https://font.missgorealty.com/fonts/GmarketSansTTFLight.ttf") format("truetype");
    font-weight: 200;
}
@font-face {
    font-family: "Sandoll Samliphopangche";
    src: url("https://dev.missgoauction.com/webview/fonts/SDSamliphopangcheBasic.otf") format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: "Sandoll Samliphopangche Outline";
    src: url("https://dev.missgoauction.com/webview/fonts/SDSamliphopangcheOutline.otf") format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: "M PLUS 1";
    src: url("https://dev.missgoauction.com/webviewfonts/MPLUS1-Regular.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "M PLUS Bold";
    src: url("https://dev.missgoauction.com/webviewfonts/MPLUS1-Bold.ttf") format("truetype");
    font-weight: 600;
}
@font-face {
    font-family: "M PLUS ExtraBold";
    src: url("https://dev.missgoauction.com/webviewfonts/MPLUS1-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}
@font-face {
    font-family: "S-Core Dream1";
    src: url("https://dev.missgoauction.com/webview/fonts/SCDream1.otf") format("truetype");
    font-weight: 100;
}
@font-face {
    font-family: "S-Core Dream2";
    src: url("https://dev.missgoauction.com/webview/fonts/SCDream2.otf") format("truetype");
    font-weight: 200;
}
@font-face {
    font-family: "S-Core Dream3";
    src: url("https://dev.missgoauction.com/webview/fonts/SCDream3.otf") format("truetype");
    font-weight: 300;
}
@font-face {
    font-family: "S-Core Dream4";
    src: url("https://dev.missgoauction.com/webview/fonts/SCDream4.otf") format("truetype");
    font-weight: 400;
}
html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "GmarketSansTTF", sans-serif;
    font-weight: 500;
}
